import React from 'react';

import { css } from '@emotion/react';
import Box from '@mui/material/Box';

import FirstViewFadeSlider from './FirstViewFadeSlider';
import FirstViewLogo from '@/components/atoms/index/FirstViewLogo';

const FirstViewTopImagesBox = css({
  position: 'relative',
  width: '100%',
  height: '40vh',
});

const FirstViewTopImagesChild = css({
  position: 'absolute',
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
});

const FirstViewTopImages: React.VFC = () => {
  return (
    <Box css={FirstViewTopImagesBox}>
      <Box css={FirstViewTopImagesChild}>
        <FirstViewFadeSlider />
      </Box>
      <Box css={FirstViewTopImagesChild}>
        <FirstViewLogo />
      </Box>
    </Box>
  );
};

export default FirstViewTopImages;
