import React from 'react';

import { css } from '@emotion/react';
import Box from '@mui/material/Box';

import LogoSquare from '@/svgs/LogoSquare.svg';

const FirstViewLogoBox = css({
  backgroundColor: 'rgba(255, 255, 255, 0.6)',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

const FirstViewLogoContent = css({
  verticalAlign: 'middle',
  height: '50%'
});

const FirstViewLogo: React.VFC = () => {
  return (
    <Box css={FirstViewLogoBox}>
      <LogoSquare css={FirstViewLogoContent} />
    </Box>
  );
};

export default FirstViewLogo;
