import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

import { css, keyframes } from '@emotion/react';

const horizontalSlide = keyframes({
  'from': {
    transform: 'translateX(0)',
  },
  'to': {
    transform: 'translateX(-100%)',
  }
});

const sliderItems = css({
  display: 'flex',
  listStyle: 'none',
  boxSizing: 'border-box',
  margin: 0,
  padding: 0,
  animationName: horizontalSlide,
  animationDuration: '36s',
  animationIterationCount: 'infinite',
  animationTimingFunction: 'linear',
  animationDelay: '1s',
  animationFillMode: 'both',
});

const sliderItem = css({
  width: '20vw',
  maxHeight: '15vh',
});

const sliderItemImage = css({
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  margin: '0 auto',
  padding: '0',
});

const FirstViewHorizontalSliderContents: React.VFC = () => {
  return (
    <ul css={sliderItems}>
      <li css={sliderItem}>
        <StaticImage
          alt="FadeSliderItem01"
          src="../../../images/index/sliderItems/image01.jpg"
          css={sliderItemImage}
        />
      </li>
      <li css={sliderItem}>
        <StaticImage
          alt="FadeSliderItem02"
          src="../../../images/index/sliderItems/image02.jpg"
          css={sliderItemImage}
        />
      </li>
      <li css={sliderItem}>
        <StaticImage
          alt="FadeSliderItem03"
          src="../../../images/index/sliderItems/image03.jpg"
          css={sliderItemImage}
        />
      </li>
      <li css={sliderItem}>
        <StaticImage
          alt="FadeSliderItem04"
          src="../../../images/index/sliderItems/image04.jpg"
          css={sliderItemImage}
        />
      </li>
      <li css={sliderItem}>
        <StaticImage
          alt="FadeSliderItem05"
          src="../../../images/index/sliderItems/image05.jpg"
          css={sliderItemImage}
        />
      </li>
    </ul>
  );
};

export default FirstViewHorizontalSliderContents;
