import React from 'react';
import { navigate } from 'gatsby-link';
import { StaticImage } from 'gatsby-plugin-image';

import { css } from '@emotion/react';
import Grid from '@mui/material/Grid';

import LinkBox from '@/components/molecules/index/LinkBox';
import ImageLinkBox from '@/components/molecules/index/ImageLinkBox';
import CorporateMessage from '@/components/molecules/index/CorporateMessage';

const linkImage = css({
  height: '100%',
});

const CorporateBlock: React.VFC = () => {
  return(
    <section>
      <Grid container>
        <Grid item xs={12} md={4}>
          <LinkBox
            backgroundColor="primary"
            linkTitleText={['Corporate ', 'History']}
            linkButtonText="会社の歴史を見る"
            handleClick={() => navigate('/history')}
          />
        </Grid>
        <Grid item xs={12} md={8}>
          <CorporateMessage />
        </Grid>
        <Grid item xs={12} md={6}>
          <ImageLinkBox
            labelName="私たちの哲学"
            labelNameEn="Philosophy"
            handleClick={() => navigate('/philosophy')}
          >
            <StaticImage
              alt="philosophy image"
              src="../../../images/firstviews/philosophy.jpg"
              css={linkImage}
              layout="fullWidth"
            />
          </ImageLinkBox>
        </Grid>
        <Grid item xs={12} md={6}>
          <ImageLinkBox
            labelName="会社情報"
            labelNameEn="Company"
            handleClick={() => navigate('/company')}
          >
            <StaticImage
              alt="company image"
              src="../../../images/firstviews/company.jpg"
              css={linkImage}
              layout="fullWidth"
            />
          </ImageLinkBox>
        </Grid>
      </Grid>
    </section>
  );
};

export default CorporateBlock;
