import React from 'react';

import { css, Theme } from '@emotion/react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CustomerVoiceFadeSlider from './CustomerVoiceFadeSlider';

const worksMessageBox = (theme: Theme) => css({
  background: theme.palette.background.default,
  height: '45vh',
  position: 'relative',
  [theme.breakpoints.up('sm')]: {
    minHeight: '30vh',
    height: '100%'
  },
});

const worksMessageChild01 = (theme: Theme) => css({
  position: 'absolute',
  top: 0,
  bottom: 0,
  right: 0,
  left: 0,
  opacity: .3,
  [theme.breakpoints.up('lg')]: {
    left: 'auto',
    width: '60%',
    opacity: .5,
  },
});

const worksMessageChild02 = css({
  position: 'absolute',
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
});

const worksMessageContentsBox = css({
  height: '100%',
  display: 'flex',
  alignItems: 'center',
});

const worksMessageContents = (theme: Theme) => css({
  width: '90%',
  margin: '1.5rem auto',
  [theme.breakpoints.up('lg')]: {
    margin: '3rem auto',
  },
});

const worksMessageTitle = (theme: Theme) => css({
  marginBottom: '1rem',
  fontSize: '1.2rem',
  [theme.breakpoints.up('sm')]: {
    fontSize: '1.3rem',
  },
  [theme.breakpoints.up('lg')]: {
    marginBottom: '1.5rem',
    fontSize: '1.5rem',
  },
});

const titleBrAdjust = (theme: Theme) => css({
  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
});

const brAdjust = css({
  display: 'inline-block',
});

const WorksMessage: React.VFC = () => {
  return (
    <Box css={worksMessageBox}>
      <Box css={worksMessageChild01}>
        <CustomerVoiceFadeSlider />
      </Box>
      <Box css={worksMessageChild02}>
        <Box css={worksMessageContentsBox}>
          <Box css={worksMessageContents}>
            <Typography variant="h2" css={worksMessageTitle}>
              <span css={brAdjust}>富士見市立みずほ台小学校の</span>
              <span css={brAdjust}>児童の皆様</span>
              <br css={titleBrAdjust} />
              <span css={brAdjust}>一人一人から</span>
              <span css={brAdjust}>感謝の言葉をいただきました。</span>
            </Typography>
            <Typography>
              <span css={brAdjust}>校長先生をはじめ先生方、</span>
              <span css={brAdjust}>児童の皆様には工事期間中、</span>
              <span css={brAdjust}>大変お世話になりました。</span>
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default WorksMessage;
