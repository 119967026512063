import React from 'react';

import { css, Theme } from '@emotion/react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const corporateMessageBox = (theme: Theme) => css({
  background: theme.palette.backgroundDark.default,
  minHeight: '30vh',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
});

const corporateMessageContents = css({
  width: '90%',
  margin: '3rem auto',
});

const corporateMessageTitle = css({
  marginBottom: '1.5rem',
  color: '#ffffff',
});

const corporateMessageText = css({
  color: '#ffffff',
});

const brAdjust = css({
  display: 'inline-block',
});

const CorporateMessage: React.VFC = () => {
  return (
    <Box css={corporateMessageBox}>
      <Box css={corporateMessageContents}>
        <Typography variant="h2" css={corporateMessageTitle}>
          創業60年の伝統
        </Typography>
        <Typography css={corporateMessageText}>
          <span css={brAdjust}>1959年、富士見市で</span>
          <span css={brAdjust}>創業してから60年、</span>
          <br />
          <span css={brAdjust}>皆様に支えられて</span>
          <span css={brAdjust}>ここまで活動してまいりました。</span>
          <br />
          <span css={brAdjust}>これからも時代の変化に</span>
          <span css={brAdjust}>スピーディに対応し、</span>
          <br />
          <span css={brAdjust}>技術やサービスの向上に</span>
          <span css={brAdjust}>努めてまいります。</span>
          <br />
          <span css={brAdjust}>今後とも皆様のご指導、</span>
          <span css={brAdjust}>ご愛顧を賜りますようお願い申し上げます。</span>
        </Typography>
      </Box>
    </Box>
  );
};

export default CorporateMessage;
