import React from 'react';

import { css, Theme } from '@emotion/react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

type ImageLinkLabelProps = {
  labelName: string;
  labelNameEn: string;
}

const imageLinkLabelBox = (theme: Theme) => css({
  backgroundColor: theme.palette.background.default,
  position: 'absolute',
  bottom: 0,
  left: 0,
  padding: '.75rem 2rem',
});

const enLabelStyle = css({
  fontSize: '1.3rem',
});

const ImageLinkLabel: React.VFC<ImageLinkLabelProps> = (props) => {
  const {labelName, labelNameEn} = props;

  return (
    <Box css={imageLinkLabelBox}>
      <Typography css={enLabelStyle}>
        { labelNameEn }
      </Typography>
      <Typography>
        { labelName }
      </Typography>
    </Box>
  );
};

export default ImageLinkLabel;
