import React from 'react';

import { css } from '@emotion/react';
import Box from '@mui/material/Box';

import FirstViewHorizontalSliderContents from './FirstViewHorizontalSliderContents';

const sliderStyles = css({
  display: 'flex',
  alignItems: 'center',
  overflow: 'hidden',
  maxHeight: '15vh',
});

const FirstViewHorizontalSlider: React.VFC = () => {
  return (
    <Box css={sliderStyles}>
      <FirstViewHorizontalSliderContents />
      <FirstViewHorizontalSliderContents />
      <FirstViewHorizontalSliderContents />
    </Box>
  );
};

export default FirstViewHorizontalSlider;
