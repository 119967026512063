import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

import { css, keyframes } from '@emotion/react';
import Box from '@mui/material/Box';

const sliderStyles = css({
  position: 'relative',
  height: '100%',
  margin: '0 auto',
  padding: '0',
  textAlign: 'center',
});

const sliderItemsUl = css({
  margin: 0,
  padding: 0,
  listStyle: 'none',
});

const sliderItemLi = css({
  width: '100%',
  height: '100%',
  position: 'absolute',
  top: 0,
  left: 0,
  animationIterationCount: 'infinite',
  animationDuration: '35s',
});

const sliderItemImage = css({
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  margin: '0 auto',
  padding: '0',
});

const sliderAnimation = keyframes({
  '0%': {
    opacity: 0,
  },
  '2.86%': {
    opacity: 1,
  },
  '20%': {
    opacity: 1,
  },
  '25.71%': {
    opacity: 0,
  },
  '100%': {
    opacity: 0,
  }
});

const sliderItem01 = css({
  animationName: sliderAnimation,
  animationDelay: '-1s',
});

const sliderItem02 = css({
  animationName: sliderAnimation,
  animationDelay: '6s',
  opacity: 0,
});

const sliderItem03 = css({
  animationName: sliderAnimation,
  animationDelay: '13s',
  opacity: 0,
});

const sliderItem04 = css({
  animationName: sliderAnimation,
  animationDelay: '20s',
  opacity: 0,
});

const sliderItem05 = css({
  animationName: sliderAnimation,
  animationDelay: '27s',
  opacity: 0,
});

const FirstViewFadeSlider: React.VFC = () => {
  return(
    <Box css={sliderStyles}>
      <ul css={sliderItemsUl}>
        <li css={[sliderItemLi, sliderItem01]}>
          <StaticImage
            alt="FadeSliderItem01"
            src="../../../images/index/fadeSliderItems/image01.jpg"
            css={sliderItemImage}
          />
        </li>
        <li css={[sliderItemLi, sliderItem02]}>
          <StaticImage
            alt="FadeSliderItem02"
            src="../../../images/index/fadeSliderItems/image02.jpg"
            css={sliderItemImage}
          />
        </li>
        <li css={[sliderItemLi, sliderItem03]}>
          <StaticImage
            alt="FadeSliderItem03"
            src="../../../images/index/fadeSliderItems/image03.jpg"
            css={sliderItemImage}
          />
        </li>
        <li css={[sliderItemLi, sliderItem04]}>
          <StaticImage
            alt="FadeSliderItem04"
            src="../../../images/index/fadeSliderItems/image04.jpg"
            css={sliderItemImage}
          />
        </li>
        <li css={[sliderItemLi, sliderItem05]}>
          <StaticImage
            alt="FadeSliderItem05"
            src="../../../images/index/fadeSliderItems/image05.jpg"
            css={sliderItemImage}
          />
        </li>
      </ul>
    </Box>
  );
};

export default FirstViewFadeSlider;
