import React from 'react';

import Grid from '@mui/material/Grid';

import FirstViewMessage from '@/components/atoms/index/FirstViewMessage';
import FirstViewTopImages from '@/components/molecules/index/FirstViewTopImages';
import FirstViewHorizontalSlider from '@/components/molecules/index/FirstViewHorizontalSlider';

const FirstView:React.VFC = () => {
  return(
    <section>
      <Grid container>
        <Grid item xs={12} md={5}>
          <FirstViewTopImages />
        </Grid>
        <Grid item xs={12} md={7}>
          <FirstViewMessage />
        </Grid>
        <Grid item xs={12}>
          <FirstViewHorizontalSlider />
        </Grid>
      </Grid>
    </section>
  );
};

export default FirstView;
