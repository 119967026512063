import React from 'react';

import { css, Theme } from '@emotion/react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const FirstViewMessageBox = (theme: Theme) => css({
  background: theme.palette.primaryGradation.default,
  height: '100%',
  minHeight: '25vh',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

const FirstViewMessageWrapper = css({
  width: '100%'
});

const FirstViewTitleText = (theme: Theme) => css({
  width: '90%',
  margin: '1.5rem auto',
  textShadow: '1px 1px 3px #231815',
  fontSize: '1.8rem',
  [theme.breakpoints.up('lg')]: {
    fontSize: '2.7rem',
  },
});

const FirstViewMessageText = (theme: Theme) => css({
  width: '90%',
  margin: '1rem auto',
  fontSize: '1rem',
  color: '#ffffff',
  [theme.breakpoints.up('lg')]: {
    fontSize: '1.2rem',
  },
});

const brAdjust = css({
  display: 'inline-block',
});

const englishTitle = css({
  fontStyle: 'italic',
});

const FirstViewMessage: React.VFC = () => {
  return (
    <Box css={FirstViewMessageBox}>
      <Box css={FirstViewMessageWrapper}>
        <Typography variant="h1" css={FirstViewTitleText}>
          <span css={[brAdjust, englishTitle]}>evolution is a challenge</span>
          <br />
          <span css={brAdjust}>100年。</span>
          <span css={brAdjust}>その先の未来へ。</span>
        </Typography>
        <Typography css={FirstViewMessageText}>
          <span css={brAdjust}>株式会社 富士見工務店は、</span>
          <br />
          <span css={brAdjust}>お客様に必要とされる</span>
          <span css={brAdjust}>建設会社を目指し、</span>
          <br />
          <span css={brAdjust}>これからも進化し</span>
          <span css={brAdjust}>チャレンジしてまいります。</span>
        </Typography>
      </Box>
    </Box>
  );
};

export default FirstViewMessage;
