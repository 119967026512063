import React from 'react';
import { PageProps } from 'gatsby';

import Layout from '../layouts/Layout';
import FirstView from '@/components/organisms/index/FirstView';
import WorksBlock from '@/components/organisms/index/WorksBlock';
import CorporateBlock from '@/components/organisms/index/CorporateBlock';
import ContactLinkBlock from '@/components/organisms/common/ContactLinkBlock';
import BottomMenu from '@/components/organisms/common/BottomMenu';
import Seo from '@/components/organisms/common/Seo';

const IndexPage: React.VFC<PageProps> = () => {
  return (
    <Layout>
      <Seo
        title="Top"
        top
      />
      <FirstView />
      <WorksBlock />
      <CorporateBlock />
      <ContactLinkBlock />
      <BottomMenu />
    </Layout>
  );
};

export default IndexPage;
